import { combineReducers } from 'redux';

import patientReducer from './patients/reducer';
import doctorReducer from './doctor/reducer';
import paymentReducer from './payment/reducer';

const rootReducer = combineReducers({
  patient: patientReducer,
  doctor: doctorReducer,
  payment:paymentReducer
});

export default rootReducer;
