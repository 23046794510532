import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#0F2D3A'
  },
  secondary: {
    contrastText: white,
    main: '#5C7C8D'
  },
  error: {
    contrastText: white,
    dark: colors.brown[900],
    main: colors.brown[600],
    light: colors.brown[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.indigo[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
