import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import styled from 'styled-components';
import _ from 'lodash';

const Container = styled.div`
  float: right;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 47px;
  height: 15px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 35px;
    top: 50%;
    left: 1px;
    background: #cd1f2c;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #b3b3b3;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

const SwitchComponent = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      placeholder: props.component.placeholder,
      labelMM: props.component.labelMM,
      labelEN: props.component.labelEN
    };

    this._handleChanged = this._handleChanged.bind(this);
  }

 
  _handleChanged = e => {
    console.log("target_xxx",e.target.checked)
    this.setState({ value: e.target.checked ? "en" : "my" }, () =>
      this.props.onChange(this.state.value)
    );
  };

  render() {
    console.log('xxx_switch', this.state.value);
    return (
      <Container>
        <Label>
          <span style={{color:'green'}}>{this.state.labelMM}</span>
          <Input
            checked={this.state.value === "en" ? true : false}
            type="checkbox"
            onChange={this._handleChanged}
          />
          <Switch />
          <span style={{color:'green'}}>{this.state.labelEN}</span>
        </Label>
      </Container>
    );
  }
};

export default class SwitchCustom extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'Switch Custom',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: SwitchCustom.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'language',
      label: 'Default Label'
    });
  }

  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {

    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  updateValue(payload) {
    this.value = payload;
    this.valueChange.emit(payload);
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  /** End ---  */

  attachReact(element) {
    const instance = ReactDOM.render(
      <SwitchComponent
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
