import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import ImageCrop from './Image';
import _ from 'lodash';

const Crop = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      image: props.component.key,
      disabled: props.component.disabled,
      schema: props.schema || {},
      croppedImageUrl: null,
      base64Value: null
    };
  }

  handleGetImage = base64Value => {
    this.setState({ value: base64Value }, () =>
      this.props.onChange(this.state.value)
    );
  };

  handleUploadClick = event => {
    console.log('event_xxxx', event.target.files[0]);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function(e) {
      this.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);
    console.log('url_xxxx', url); // Would see a path?

    this.setState({
      mainState: 'uploaded',
      selectedFile: event.target.files[0],
      imageUploaded: 1
    });
  };

  imageResetHandler = event => {
    console.log('Click!');
    this.setState({
      mainState: 'initial',
      selectedFile: null,
      imageUploaded: 0
    });
  };

  render() {
    const { value, image, disabled, selectedFile } = this.state;
    if (!value) {
      return (
        <ImageCrop
          imgId={image}
          value={value}
          selectedFile={selectedFile}
          disabled={disabled}
          getBase64Image={image => this.handleGetImage(image)}
          onChange={event => this.handleUploadClick(event)}
          onImageReset={event => this.imageResetHandler(event)}
        />
      );
    }
    return (
      <ImageCrop
        imgId={image}
        value={value}
        disabled={disabled}
        selectedFile={selectedFile}
        getBase64Image={image => this.handleGetImage(image)}
        onChange={event => this.handleUploadClick(event)}
        onImageReset={event => this.imageResetHandler(event)}
      />
    );
  }
};

export default class ReactImage extends ReactComponent {
  constructor(component, options, data) {
    super(component, options, data);
  }

  static get builderInfo() {
    return {
      title: 'Image Custom',
      icon: 'image',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: ReactImage.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'image',
      label: 'Image'
    });
  }

  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {
    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  /** End ---  */

  attachReact(element) {
    const instance = ReactDOM.render(
      <Crop
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
