import Number from './NumberCustom/Number';
import Recaptcha from './RECAPTCHA/Recaptcha';
import ContentCustom from './ContentCustom/Content';
import ImageCustom from './ImageCustom/ImageCustomCrop';
import CarouselComponent from './Carousal/CarouselComponent';
import RadioComponent from './RedioCustom/RadioComponent';
import CardView from './CardView/CardView';
import BrandView from './Brand/BrandView';
import CheckboxCustom from './MemoryComponent/MemoryComponent';
import SwitchCustom from './Switch/Switch';
export { default as GoogleAnalytics } from './GoogleAnalytics';
export { default as ScrollReset } from './ScrollReset';
export { default as Label } from './Label';
export { default as Page } from './Page';
export { default as Alert } from './Alert';

export default {
  numbercustom: Number,
  reCaptchaCustom:Recaptcha,
  contentCustom:ContentCustom,
  image:ImageCustom,
  selectCustom:CarouselComponent,
  radioCustom: RadioComponent,
  cardview:CardView,
  checkboxCustom:CheckboxCustom,
  logoview:BrandView,
  language:SwitchCustom
};










