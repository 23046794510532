import React, { Component, useState, useRef, useEffect } from 'react';
import {
  CardWrapper,
  CardImage,
  CardTextWrapper,
  CardTextLabel,
  CardTextTitle,
  Image
} from './Styled';
import Tilt from 'react-parallax-tilt';
import { currencyFormat } from 'utils/common';
import BlurImg from '../../assets/images/blur.jpg';

function LazyImage({ src, alt, lqip }) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);

  return (
    <img
      loading="lazy"
      src={loaded ? src : lqip}
      alt={alt}
      ref={imgRef}
      onLoad={() => setLoaded(true)}
    />
  );
}
export default class CardStyled extends Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    let { id, value, title } = this.props;

    return (
      <Tilt key={id}>
        <CardWrapper key={id} onClick={this.handleClick}>
          <CardImage>
            <Image>
              <LazyImage
                aspectRatio={16 / 9}
                lqip={BlurImg}
                src={value.products[0].productInfo.defaultImg}
                alt={title}
              />
            </Image>
          </CardImage>
          <CardTextWrapper>
            <CardTextLabel>{value.model.name}</CardTextLabel>
            <CardTextTitle>
              From {currencyFormat(value.products[0].productInfo.rrp)}
            </CardTextTitle>
          </CardTextWrapper>
        </CardWrapper>
      </Tilt>
    );
  }
}
