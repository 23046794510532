const initialState = {
    success: false,
    joined: false,
    resPayment: null,
    refNumber: null,
    expiredAt: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'PAYMENT_SUCCESS':
            return {
                ...state,
                success: action.payload.success,
                resPayment: action.payload.requestPayment,
            };

        case 'JUST_JOINED':
            return {
                ...state,
                joined: action.payload.joined,
                resPayment: action.payload.requestPayment,
                refNumber:action.payload.referenceNumber,
                expiredAt:action.payload.expiredAt
            };

        default:
            return state
    }
}

export default reducer