import React, { Component } from 'react';

export default class BootstrapStyled extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      options,
      propertyValue,
      propertyLabel,
      onChange,
      value,
      show,
      labelName,
      labelValue
    } = this.props;

    return (
      <div className="d-flex flex-wrap">
        {show && show ? (
          <div className="input-group input-group-sm">
            <label
              className="form-check-label"
              for="inlineRadio"
              name="showlabel"
              style={{
                color: '#635f5f',
                fontWeight: 'bold',
                fontSize: '15px'
              }}>
                
              {labelName && labelName ? labelName + ' - ' : ''}
              {labelValue}
            </label>
          </div>
        ) : null}

        {options &&
          options.length > 0 &&
          options.map((item, index) => {
            return (
              <>
                <div className="form-check form-check-inline" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={item[propertyValue ?? 'value']}
                    name={item[propertyValue ?? 'value']}
                    value={item[propertyValue ?? 'value']}
                    onChange={onChange}
                    checked={
                      value &&
                      value.toString() ===
                        item[propertyValue ?? 'value'].toString()
                    }
                  />

                  <label className="form-check-label" for="inlineRadio2">
                    {item[propertyLabel ?? 'label']}
                  </label>
                </div>
              </>
            );
          })}
      </div>
    );
  }
}
