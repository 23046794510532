import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import CardStyled from './CardStyled';
import styled from 'styled-components';
import _ from 'lodash';

const CardContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 3px;
`;

const CardView = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      dataSrcType: props.component.dataSrc === 'values' ? true : false,
      values: props.component.data?.values ? props.component.data.values : [],
      customValues: props.component.data?.custom
        ? this.getCustomItems(props.component.data.custom)
        : [],
      selectedValue: props.value
    };
    this._handleChanged = this._handleChanged.bind(this);
  }

  _handleChanged(value) {
    console.log('select_xxx', value);
    this.setState({ selectedValue: value }, () =>
      this.props.onChange(this.state.selectedValue)
    );
  }

  getOptionItems = component => {
    let { dataSrc, data } = component;
    let values = [];
    try {
      if (dataSrc === 'values') {
        values = data.values;
      } else {
        values = data?.custom ? this.getCustomItems(data.custom) : [];
      }
    } catch (e) {
      return [];
    }

    return values;
  };

  getCustomItems = values => {
    try {
      if (values != null) {
        values = JSON.parse(values.split('=').pop());
      }
    } catch (e) {
      return [];
    }

    return values;
  };

  checkValue = values => {
    try {
      if (values != null) {
        values = JSON.parse(values.split('=').pop());
      }
    } catch (e) {
      return null;
    }
    return values;
  };

  render() {


    const { values, customValues, dataSrcType } = this.state;
    let optionData = dataSrcType ? values : customValues;

    console.log("xxx_option",optionData)

    return (
      <CardContainer>
        {optionData &&
          optionData.length > 0 &&
          optionData.map((data, index) => {
            return (
              <CardStyled
                key={index}
                id={data.model._id}
                title={data.model.name}
                imgUrl={"https://apibranch.ayainnovation.com/setting/getImage/63f2fb6a2d00af03e46fcb17"}
                value={data}
                onChange={this._handleChanged}
              />
            );
          })}
      </CardContainer>
    );
  }
};
export default class CardViewCustom extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'CardView',
      icon: 'fas fa-list',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: CardViewCustom.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'cardview',
      label: 'CardView'
    });
  }

  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {

    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  updateValue(payload) {
    this.value = payload; // Should be updated first
    this.valueChange.emit(payload); // Should be called after this.value update
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  /** End ---  */

  
  attachReact(element) {
    const instance = ReactDOM.render(
      <CardView
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
