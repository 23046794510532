import { GET_PATIENTS, SELECT_PATIENT } from '../types';

const initState = {
  patients: [],
  selectPatient: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_PATIENTS:
      return { patients: action.payload, selectPatients: {} };
    case SELECT_PATIENT:
      return { ...state, selectPatient: { ...action.payload } };
    default:
      return state;
  }
};
