import imageCompression from 'browser-image-compression';
import axios from 'axios';

export const convertBlobToBase64 = compressedFile =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(compressedFile);
  });

export const uploadFileAmazon = async (image, apiUpload) => {
  try {
    const baseToFile = await dataURLtoFile(image.url, image.name);

    const ramdom = random10to6th_concatToTimestamp();
    const options = {
      maxWidthOrHeight: 1000,
      fileType: 'string'
    };

    const compressedFile = await imageCompression(baseToFile, options);
    const compressedBase64 = await convertBlobToBase64(compressedFile);

    const requestBody = {
      url: compressedBase64,
      originalName: 'image' + ramdom,
      type: image.type
    };

    let body = {};
    let response = {};

    body = { file: requestBody, path: 'icu/icunumber/casenumber' };
    response = await apiUpload.post('/setting/awsupload', body);

    const reponseBody = {
      type: image.type
    };

    if (response.data.status === 'success') {
      return (
        Object.assign(reponseBody),
        {
          link: response.data.data,
          ...reponseBody
        }
      );
    } else {
      throw new Error("Server response wasn't OK");
    }
  } catch (e) {
    console.log('caught error', e);
  }
};

export const uploadSignatureAmazon = async (image, apiUpload) => {
  try {
    const baseToFile = await dataURLtoFile(image, 'signature');

    const ramdom = random10to6th_concatToTimestamp();
    const options = {
      maxWidthOrHeight: 1000,
      fileType: 'string'
    };

    const compressedFile = await imageCompression(baseToFile, options);
    const compressedBase64 = await convertBlobToBase64(compressedFile);

    const requestBody = {
      url: compressedBase64,
      originalName: 'signature' + ramdom,
      type: image.type
    };

    let body = { file: requestBody, path: 'icu/icunumber/casenumber' };
    let response = await apiUpload.post('/setting/awsupload', body);

    console.log('res_xxx', response);
    if (response.data.status === 'success') {
      return response.data;
    } else {
      throw new Error("Server response wasn't OK");
    }
  } catch (e) {
    console.log('caught error', e);
  }
};

export const uploadPDFAmazon = async (image, apiUpload) => {
  try {
    const baseToFile = await dataURLtoFile(image.url, image.name);

    const ramdom = random10to6th_concatToTimestamp();
    const compressedBase64 = await convertBlobToBase64(baseToFile);
    var base64result = compressedBase64.split(',')[1];

    const requestBody = {
      url: base64result,
      originalName: 'pdf' + ramdom,
      type: image.type
    };

    const body = { file: requestBody, path: 'icu/icunumber/casenumber' };
    let response = await apiUpload.post('/setting/awsupload', body);

    const reponseBody = {
      type: image.type
    };

    if (response.data.status === 'success') {
      return (
        Object.assign(reponseBody),
        {
          link: response.data.data,
          ...reponseBody
        }
      );
    } else {
      throw new Error("Server response wasn't OK");
    }
  } catch (e) {
    console.log('caught error', e);
  }
};

export const random10to6th_concatToTimestamp = () => {
  return Math.round(
    Math.random() * Math.pow(10, 6) + '' + new Date().getTime()
  );
};

export const dataURLtoFile = async (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getBase64 = file => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    console.log(reader.result);
  };
  reader.onerror = function(error) {
    console.log('Error: ', error);
  };
};

const getExtension = filename => {
  var parts = filename.split('/');
  return parts[parts.length - 1];
};

export const checkType = filename => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
    case 'pdf':
      return true;
  }
  return false;
};

export const chackTypeImage = filename => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
      return true;
  }
  return false;
};

export const externalAPI = (route, body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(route, body)
      .then(res => {
        if (res.status === 200) {
          if (res.data.errorMsg === 'Success') {
            resolve(res.data);
          } else {
            throw new Error("Server response wasn't OK");
          }
        }
      })
      .catch(error => reject(error));
  });
};

export const checkNRICFormat = nricData => {
  if (typeof nricData.nric === 'object' && nricData.nric !== null) {
    let { code, name, type, number } = nricData.nric;
    return code + '/' + name + '(' + type + ')' + number;
  } else if (
    typeof nricData.oldNric === 'string' &&
    nricData.oldNric.trim().length !== 0
  ) {
    return nricData.oldNric;
  } else if (
    typeof nricData.passportInfo === 'object' &&
    nricData.passportInfo !== null
  ) {
    let { countryCode, passportNo } = nricData.passportInfo;
    return countryCode + '-' + passportNo;
  } else return '-';
};

export const currencyFormat = balance => {
  const response = balance.toLocaleString('en-Kyat', {
    style: 'currency',
    currency: 'MMK'
  });
  return response;
};
//extract 'awesome' text from input string and return rest of the string
export const extractColor = (color, availableColors) => {
  let filter = availableColors.find(
    x => x.value.toString().trim() === color.toString().trim()
  );
  console.log('filter', filter);
  return filter?.code || '#ffffff';
  // switch (inputString.toLowerCase().replace(/\s/g, '')) {
  //   case 'pinkgold':
  //     return '#E0BFB8';
  //   case 'beige':
  //     return '#F5F5DC';
  //   case 'graygreen':
  //     return '#565c59';
  //   case 'burgandy':
  //     return '#8c7a84';
  //   case 'sapphire':
  //     return '#9babb0';
  //   case 'copper':
  //     return '#ce837a';
  //   case 'awesomeblue':
  //     return '#a7c6ed';
  //   case 'awesomeblack':
  //     return '#454546';
  //   case 'awesomepeach':
  //     return '#ffd4b1';
  //   case 'awesomemint':
  //     return '#abc4c0';
  //   case 'awesomegray':
  //     return '#5f6367';
  //   case 'awesomewhite':
  //     return '#e9e9e7';
  //   case 'cream':
  //     return '#f0eae0';
  //   case 'lavender':
  //     return '#d4c7d9';
  //   case 'lightgreen':
  //     return '#90EE90';
  //   case 'violet':
  //     return '#7F00FF';
  //   case 'darkred':
  //     return '#8b0000';
  //   default:
  //     return inputString;
  // }
};

export function numberWithCommas(str) {
  // check str is number if it is not number return str
  if (isNaN(str)) {
    return str;
  }

  if (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
}
