import React, { Component, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const ElasticCarousel = styled(Carousel)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  padding: 1rem 0 1rem 0;

  .rec-arrow {
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: rgba(103, 58, 183, 0.1);
    color: #999;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    background-color: #ba242d !important;
    box-shadow: 0 0 2px 0 #333;
  }

  .rec-arrow-left:disabled,
  .rec-arrow-right:disabled {
    cursor: not-allowed !important;
    background-color: #c0bebe !important;
  }

  .rec-arrow:hover:enabled,
  .rec-arrow:focus:enabled {
    color: #fff;
    cursor: pointer;
    background-color: #ba242d !important;
    box-shadow: 0 0 2px 0 #333;
  }

  .rec-pagination {
    margin-top: 5px !important;
  }

  .rec-dot_active {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: #e4cece;
    font-size: 1.3em;
    content: '';
    height: 8px;
    width: 8px;
    box-shadow: 0 0 1px 3px #d6a3a6;
    border-radius: 50%;
    outline: none;
  }
  .rec-dot {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    font-size: 1.3em;
    content: '';
    height: 8px;
    width: 8px;
    box-shadow: 0 0 1px 2px rgb(0 0 0 / 50%);
    border-radius: 50%;
    outline: none;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  // width: 200px;
  width: 100%;
  background-color: #fff;
  // color: #fff;
  margin: 0 5px;
  font-size: 4em;
  border-radius: 5px;
`;

const breakPoints = [
  { width: 500, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 960, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 }
];

function LazyImage({ src, alt, lqip }) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      console.log("ref_xx",imgRef)
      setLoaded(true);
    }
  }, []);

  return (
    <img
      loading="lazy"
      src={loaded ? src : lqip}
      alt={alt}
      ref={imgRef}
      onLoad={() => setLoaded(true)}
    />
    // <LazyLoadImage
    //   width="195"
    //   alt={alt}
    //   effect="blur"
    //   ref={imgRef}
    //   src={loaded ? src : lqip}
    //   onLoad={() =>  setLoaded(true)}
    // />
  );
}
export default class CarouselStyled extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <ElasticCarousel
          breakPoints={breakPoints}
          enableAutoPlay
          autoPlaySpeed={3000}>
          {this.props.options &&
            this.props.options.map((item, index) => (
              <Item key={index}>
                <LazyLoadImage
                  width="195"
                  alt={'img'}
                  effect="blur"
                  src={item.label}
                />
                {/* <LazyImage
                  aspectRatio={16 / 9}
                  lqip={BlurImg}
                  src={item.label}
                  alt={'img'}
                /> */}
              </Item>
            ))}
        </ElasticCarousel>
      </>
    );
  }
}
