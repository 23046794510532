import React, { Component } from 'react';
import styled from 'styled-components';
import { currencyFormat } from 'utils/common';

const CheckboxDiv = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  .checkbox-wrapper-16 *,
  .checkbox-wrapper-16 *,
  .checkbox-wrapper-16 *:after,
  .checkbox-wrapper-16 *:before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-16 .checkbox-input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
    border-color: #cd1f2c;
    box-shadow: 0 5px 10px #d4cdc9;
    color: #b90c19;
    padding: 8px;
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
    background-color: #cd1f2c;
    border-color: #cd1f2c;
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-icon,
  .checkbox-wrapper-16
    .checkbox-input:checked
    + .checkbox-tile
    .checkbox-label {
    color: #cd1f2c;
  }
  .checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile {
    border-color: #cd1f2c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #f5e9e6;
  }
  .checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
  }

  .checkbox-wrapper-16 .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    min-height: 2rem;
    border-radius: 0.5rem;
    border: 2px solid #e7e0e0;
    background-color: #fff;
    box-shadow: 0 5px 10px #d4cdc9;
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
    padding: 8px;
  }
  .checkbox-wrapper-16 .checkbox-tile:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #483e3e;
    background-color: #fff;
    border-radius: 50%;
    top: -0.6em;
    right: -0.4em;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .checkbox-wrapper-16 .checkbox-tile:hover {
    border-color: #d4cdc9;
  }
  .checkbox-wrapper-16 .checkbox-tile:hover:before {
    transform: scale(1);
    opacity: 1;
  }

  .checkbox-wrapper-16 .checkbox-icon {
    transition: 0.375s ease;
    color: #494949;
  }
  .checkbox-wrapper-16 .checkbox-icon svg {
    width: 3rem;
    height: 3rem;
  }

  .checkbox-wrapper-16 .checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
  }
`;

const MainDiv = styled.div``;

export default class MemoryStyled extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { options, value, onChange } = this.props;
    let checkedValue = value ? JSON.parse(value) : value;

    console.log('options_xxx', options);
    return (
      <MainDiv>
        {options &&
          options.length > 0 &&
          options
            .filter(opt => opt.productInfo.inStock)
            .map((item, index) => {
              return (
                <CheckboxDiv key={index}>
                  <div className="checkbox-wrapper-16">
                    <label className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        onChange={onChange}
                        checked={
                          checkedValue?.productInfo._id === item.productInfo._id
                        }
                        name={item.productInfo._id}
                        ref={item.productInfo._id}
                        value={JSON.stringify(item)}
                        key={item.productInfo._id}
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">
                          {item.productInfo.specification}
                        </span>
                        <span className="checkbox-label">
                          {currencyFormat(item.productInfo.rrp)}
                          <span
                            style={{ fontSize: '10px', paddingLeft: '2px' }}>
                            MMK
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </CheckboxDiv>
              );
            })}
      </MainDiv>
    );
  }
}
