import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import MemoryStyled from './MemoryStyled';
import _ from 'lodash';

const MemoryComponent = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      values: props.component.values ? props.component.values : [],
      selectedValue: JSON.stringify(props.value),
      selectString: props.value,
      selectedOject: { memory: props.value?.specification, data: props.value }
    };
    this._handleChanged = this._handleChanged.bind(this);
  }

  _handleChanged(e) {
    this.setState({ selectedValue: e.target.value }, () =>
      this.props.onChange(JSON.parse(this.state.selectedValue))
    );
  }

  render() {
    const { placeholder, values, selectedValue } = this.state;
    let options = values;

    console.log("options_xxx",values)
   
    return (
      <MemoryStyled
        options={options}
        placeholder={placeholder}
        value={selectedValue}
        onChange={this._handleChanged}
      />
    );
  }
};
export default class Checkbox extends ReactComponent {
  constructor(component, options, data) {
    super(component, options, data);
    this.reactInstance = null;
  }

  static get builderInfo() {
    return {
      title: 'Checkbox Custom',
      icon: 'check-square',
      group: 'advanced',
      documentation: '',
      weight: -10,
      schema: Checkbox.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'checkboxCustom',
      label: 'Checkbox Custom'
    });
  }
  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
   // console.log('xxx_com_dataValue', this.dataValue);
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {

    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
   
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {

    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  updateValue(payload) {
    this.value = payload; 
    this.valueChange.emit(payload); 
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  setReactInstance(element) {
    this.reactInstance = element;
  }
  
  /** End ---  */

  attachReact(element) {
    const instance = ReactDOM.render(
      <MemoryComponent
        component={this.component} 
        value={this.dataValue} 
        onChange={this.updateValue} 
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
