import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import CustomRadioStyled from './CustomStyled';
import BootstrapStyled from './BootstrapStyled';
import _ from 'lodash';

const RadioComponent = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      values: props.component.values ? props.component.values : [],
      selectedValue: props.value,
      propertyName: props.propertyName,
      propertyValue: props.propertyValue,
      labelValue: props.component.labelValue
    };
    this._handleChanged = this._handleChanged.bind(this);
  }

  _handleChanged(e) {
    console.log('xxx_target_value', this.state.values);
    console.log('xxx_target_value', e.target.value);
    let findAmount = this.state.values.find(
      x => x.value.toString() === e.target.value.toString()
    );
    console.log('xxx_find_value', findAmount);
    this.setState(
      {
        selectedValue: e.target.value,
        labelValue: findAmount && findAmount.downPaymentAmount
      },
      () => this.props.onChange(this.state.selectedValue)
    );
  }

  render() {
    const { placeholder, values, selectedValue, labelValue } = this.state;
    const {
      style,
      propertyLabel,
      propertyValue,
      show,
      labelName
    } = this.props.component;
    let options = values;

    // console.log('xxx_labelName', labelValue);
    if (style && style === 'customRadio') {
      return (
        <>
          {show && show ? (
            <h6
              style={{
                color: '#635f5f',
                fontWeight: 'bold',
                fontSize: '15px'
              }}>
              {labelName && labelName ? labelName + ' - ' : ''}
              {selectedValue}
            </h6>
          ) : null}

          <CustomRadioStyled
            options={options}
            placeholder={placeholder}
            value={selectedValue}
            onChange={this._handleChanged}
            propertyLabel={propertyLabel}
            propertyValue={propertyValue}
          />
        </>
      );
    } else {
      return (
          <BootstrapStyled
            show={show}
            options={options}
            placeholder={placeholder}
            value={selectedValue}
            onChange={this._handleChanged}
            propertyLabel={propertyLabel}
            propertyValue={propertyValue}
            labelValue={labelValue}
            labelName={labelName}
          />
      );
    }
  }
};

export default class Radio extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'Radio',
      icon: 'fa fa-th-list',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: Radio.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'radioCustom',
      label: 'Radio'
    });
  }

  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {
    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  updateValue(payload) {
    this.value = payload;
    this.valueChange.emit(payload);
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  /** End ---  */

  // attachReact(element) {
  //   let instance;
  //   ReactDOM.render(
  //     <RadioComponent
  //       ref={(refer) => {
  //         instance = refer;
  //       }}
  //       component={this.component} // These are the component settings if you want to use them to render the component.
  //       value={this.dataValue} // The starting value of the component.
  //       onChange={this.updateValue} // The onChange event to call when the value changes.}
  //     />,
  //     element,
  //     () => (this.reactInstance = instance)
  //   );

    
  // }

  attachReact(element) {
    const instance = ReactDOM.render(
      <RadioComponent
        component={this.component} 
        value={this.dataValue} 
        onChange={this.updateValue} 
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
