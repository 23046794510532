/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  successCard: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
    width: '100%',
    padding: '0.75rem 1.25rem',
    border: '1px solid transparent',
    borderRadius: '.25rem'
  },
  errorCard: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    width: '100%',
    padding: '0.75rem 1.25rem',
    border: '1px solid transparent',
    borderRadius: '.25rem'
  },
  typography: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#ffffff'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  icons: {
    marginLeft: 'auto !important'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  collapse: {
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    color: '#ffffff',
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: 'none'
  }
}));

const CustomSnackBar = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  let cardCmp = null;
  const message = props.message.toLowerCase();
  if (
    message.includes('success') ||
    message.includes('online') ||
    message.includes('status')
  ) {
    cardCmp = (
      <div className={classes.successCard}>
        {props.message}
        <button
          aria-label="Close"
          className="close line-height-unset"
          data-dismiss="alert"
          onClick={handleDismiss}
          type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  } else {
    cardCmp = (
      <div className={classes.errorCard}>
        {props.message}
        <button
          aria-label="Close"
          className="close line-height-unset"
          data-dismiss="alert"
          onClick={handleDismiss}
          type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  return (
    <SnackbarContent className={classes.root} ref={ref}>
      {cardCmp}
    </SnackbarContent>
  );
});

export default CustomSnackBar;
