import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import './custom.scss';

const Content = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      editorContent: undefined,
      editorState: props.value
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(props.value))
          )
        : EditorState.createEmpty(),
    };
  }

  onEditorChange(editorContent) {
    const converHtml = draftToHtml(editorContent);
    this.setState({ editorContent: converHtml });
    this.props.onChange(converHtml);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  };

  render() {
    const { editorState, editorContent } = this.state;
    return (
      <div>
        <Editor
          rawContentState={editorContent || null}
          editorState={editorState || null}
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onChange={this.onEditorChange.bind(this)}
          onEditorStateChange={this.onEditorStateChange.bind(this)}
          toolbarAlwaysVisible
        />
      </div>
    );
  }
};

export default class ContentCustom extends ReactComponent {

  static get builderInfo() {
    return {
      title: 'Content Custom',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: ContentCustom.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'contentCustom',
      label: 'Default Label',
    });
  }

  get emptyValue() {
    return "";
  }

  attachReact(element) {
    const instance = ReactDOM.render(
      <Content
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
