import React, { Component } from 'react';
import upload from '../../assets/images/upload-image-icon.svg';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  alignitems: flex-end;
  // background: grey;
  min-height: 150px;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  background: #f3f1f1;
  justify-ontent: center;
  align-items: center;
  border-radius: 8px;
  padding: 40px;
  margin: 10px;
`;

const Input = styled.input`
  display: none;
`;

const Span = styled.span`
  color: blue;
  margin: 10;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  width: 120px;
  height: 120px;
  padding: 0;
  font-size: 0.875rem;
  min-width: 0;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-radius: 8px;
  text-transform: uppercase;
  background-color: #eae5e5;
`;

const Label = styled.label`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

const CardActionArea = styled.div`
  display: flex;
  background: #f3f1f1;
  justify-ontent: center;
  align-items: center;
  border-radius: 8px;
  padding: 40px;
  margin: 10px;
`;

export default class ImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainState: "initial", 
      imageUploaded: 0,
      selectedFile: null
    };
  }

  imageResetHandler = event => {
    console.log('Click!');
    this.setState({
      mainState: 'initial',
      selectedFile: null,
      imageUploaded: 0
    });
  };

  renderUploadedState() {
    const { classes, theme, selectedFile } = this.props;
    console.log('selectedFile_xxxx', selectedFile);

    return (
      <CardActionArea onClick={this.props.onImageReset}>
        <img
          width="100%"
          src={selectedFile}
        />
      </CardActionArea>
    );
  }

  render() {
    return (
      <Main>
        <Container>
          <Input
            accept="image/*"
            id={'contained-button-file' + this.props.imgId}
            multiple
            type="file"
            onChange={this.props.onChange}
            ref={fileInput => (this.fileInput = fileInput)}
          />
          <Box>
            <Label htmlFor={'contained-button-file' + this.props.imgId}>
              <Span>
                <img alt="alert logo" src={upload} width="50px" />
              </Span>
            </Label>
          </Box>
        </Container>
      </Main>
    );
  }
}
