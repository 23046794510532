import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const FetchContext = createContext();
const { Provider } = FetchContext;

// eslint-disable-next-line react/prop-types
const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER
  });

  authFetch.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      config.headers.Language = 'English';
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  authFetch.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log('error code', code);
        authContext.logout();
        enqueueSnackbar('This is a success message!', { variant: 'success' });
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authFetch
      }}
    >
      {children}
    </Provider>
  );
};

const routWithFetchProvider = withRouter(FetchProvider);

export { FetchContext, routWithFetchProvider };
