import styled from 'styled-components';

export const CardWrapper = styled.div`
  padding: 0.3em;
  color: #ffffff;
  grid-template-areas: 'image' 'text' 'stats';
`;

export const CardImage = styled.div`
  grid-area: image;
  align-items: center;
  height: 160px;
  box-shadow: 1px 1px 7px #ddd;
  border-radius: 10px;
  display: flex;
  justify-content: center;
 // padding:20px;
 //background: beige;
`;

export const Image = styled.div`
  align-items: center;
 // height: 120px;
  width: 110px;
  //max-width: 95px !important;
  display: flex;
  padding: 15px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const CardTextWrapper = styled.div`
  grid-area: text;
`;

export const CardTextLabel = styled.span`
  color: #2a2d2f;
  font-size: 12px;
  font-weight: bold;
}
`;

export const CardTextTitle = styled.h2`
  font-weight: bold;
  margin-top: 0px;
  font-size: 11px;
  box-sizing: border-box;
  min-width: 0px;
  line-height: 1.2;
  margin: 0px;
  background: linear-gradient(
    110.78deg,
    rgb(226 230 225) -1.13%,
    rgb(233 231 227) 15.22%,
    rgb(185 179 173) 32.09%,
    rgb(236, 81, 87) 48.96%,
    rgb(238 226 234) 67.94%,
    rgb(199 206 233) 85.34%,
    rgb(208 221 225) 99.57%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: #252323c9;
`;

export const CardTextBody = styled.p`
  color: grey;
  font-size: 12px;
  font-weight: 300;
`;

export const CardStatWrapper = styled.div`
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: transparent;
`;

export const CardStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 10px;
`;

export const LinkText = styled.a`
  color: #fff;
  text-decoration: none;
`;
