import { SELECT_DOCTOR } from '../types';

const initState = {
  patients: [],
  selectDoctor: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case SELECT_DOCTOR:
      return { ...state, selectDoctor: { ...action.payload } };
    default:
      return state;
  }
};
