import React, { Component } from 'react';
import styled from 'styled-components';

const CheckboxDiv = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;

  .checkbox-wrapper-16 .checkbox-input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
   
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
    color: transparent;
    padding: 8px;
    opacity: 1
  }
  .checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
    // transform: scale(1);
    // opacity: 1;
    // background-color: #cd1f2c;
    // border-color: #cd1f2c;
  }

  .checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
    opacity: 0.6;
    background-color: #cd1f2c;
  }

  .checkbox-wrapper-16 .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    min-height: 2rem;
    border-radius: 0.5rem;
    background-color: #fff;
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
    padding: 8px;
    opacity:0.4;
    div {
      align-items: center;
      height: 70px;
      display: flex;
      padding: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .checkbox-wrapper-16 .checkbox-tile:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #483e3e;
    background-color: #fff;
    border-radius: 50%;
    top: -0.6em;
    right: -0.4em;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
`;
export default class BrandStyled extends Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    let { value, onChange, options } = this.props;

    return (
      <>
        {options &&
          options.length > 0 &&
          options.map((data, index) => {
            return (
              <CheckboxDiv key={data._id}>
                <div className="checkbox-wrapper-16">
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      onChange={onChange}
                      checked={value === data._id}
                      name={data._id}
                      value={data._id}
                      key={data._id}
                    />
                    <span className="checkbox-tile">
                      <div>
                        <img src={data.logo} alt={data.name} />
                      </div>
                    </span>
                  </label>
                </div>
              </CheckboxDiv>
            );
          })}
      </>
    );
  }
}
