import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import BrandStyled from './BrandStyled';
import styled from 'styled-components';
import _ from 'lodash';

const CardContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 5px;
`;

const BrandView = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      dataSrcType: props.component.dataSrc === 'values' ? true : false,
      values: props.component.data?.values ? props.component.data.values : [],
      customValues: props.component.data?.custom
        ? this.getCustomItems(props.component.data.custom)
        : [],
      selectedValue:
        'https://uatapibranch.ayainnovations.com/setting/getImage/64229986ced510c94d65fb3f'
    };
    this._handleChanged = this._handleChanged.bind(this);
  }

  _handleChanged(event) {
    console.log('xxx_selected_xxx', event.target.value);
    this.setState({ selectedValue: event.target.value }, () =>
      this.props.onChange(this.state.selectedValue)
    );
  }

  getOptionItems = component => {
    let { dataSrc, data } = component;
    let values = [];
    try {
      if (dataSrc === 'values') {
        values = data.values;
      } else {
        values = data?.custom ? this.getCustomItems(data.custom) : [];
      }
    } catch (e) {
      return [];
    }

    return values;
  };

  getCustomItems = values => {
    try {
      if (values != null) {
        values = JSON.parse(values.split('=').pop());
      }
    } catch (e) {
      return [];
    }

    return values;
  };

  checkValue = values => {
    try {
      if (values != null) {
        values = JSON.parse(values.split('=').pop());
      }
    } catch (e) {
      return null;
    }

    console.log('values', values);
    return values;
  };

  render() {
    const { values, customValues, dataSrcType, selectedValue } = this.state;
    let optionData = dataSrcType ? values : customValues;

   // console.log('xxx_props_xxx', this.props.component);
    //console.log('xxx_selectedValue_xxx', selectedValue);

    return (
      <>
        <CardContainer>
          <BrandStyled
            options={optionData}
            value={selectedValue}
            onChange={this._handleChanged}
          />
        </CardContainer>
      </>
    );
  }
};
export default class BrandViewCustom extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'LogoView',
      icon: 'fas fa-list',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: BrandViewCustom.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'logoview',
      label: 'LogoView'
    });
  }

  /** Added For Null Value  */

  get emptyValue() {
    return null;
  }

  get value() {
    return this.dataValue;
  }

  get dataValue() {
    if (!this.component.key) {
      return this.emptyValue;
    }
    if (!this.hasValue) {
      this.dataValue = this.emptyValue;
    }
    return _.get(this.data, this.component.key);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataValue = value;
    }
  }

  set dataValue(value) {
    if (!this.component.key) {
      return value;
    }
    _.set(this.data, this.component.key, value);
    return value;
  }

  elementInfo() {
    return super.elementInfo();
  }

  getValue() {
    return super.getValue();
  }

  setValue(value) {
    super.setValue(value);
  }

  /** End ---  */

  attachReact(element) {
    const instance = ReactDOM.render(
      <BrandView
        component={this.component} 
        value={this.dataValue} 
        onChange={this.updateValue} 
      />,
      element
    );

    return instance;
  }


  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
