import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import CarouselStyled from './CarouselStyled';

const CarouselComponent = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.component.placeholder,
      dataSrcType: props.component.dataSrc ? props.component.dataSrc === 'values' ? true : false : true,//custom
      values: props.component.data?.values ? props.component.data.values : [],
      customValues: props.component.data?.custom ? this.getCustomItems(props.component.data.custom) : [],
      selectedValue: props.value
    };
  }

  getCustomItems = (values) => {
    try {
      if (values != null) {
        values = JSON.parse(values.split('=').pop());
      }
    } catch (e) {
      return [];
    }

    return values;
  };


  render() {
    const { placeholder,  values, customValues, dataSrcType,selectedValue  } = this.state;
    let options = dataSrcType ? values : customValues;

    return (
      <CarouselStyled
        options={options}
        placeholder={placeholder}
        value={selectedValue || ''}
      />
    );
  }
};

export default class Carousel extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'Carousel',
      icon: 'fa fa-th-list',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: Carousel.schema()
    };
  }


  static schema() {
    return ReactComponent.schema({
      type: 'selectCustom',
      label: 'Carousel'
    });
  }

  attachReact(element) {
    const instance = ReactDOM.render(
      <CarouselComponent
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

}
