import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import settingsForm from './NumberCustom.settingsForm';

const Example = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      placeholder: props.component.placeholder
    }

    this._handleUpdate = this._handleUpdate.bind(this);
  }
  
  _handleUpdate(e) {
    if (e.target.validity.valid) {
      this.setState({ value: e.target.value }, () => this.props.onChange(this.state.value));
    }
  }

  render() {
    return (
      <div>
        <input className="form-control" type="number" value={this.state.value} onChange={this._handleUpdate} placeholder={this.state.placeholder || ""} />
      </div>
    );
  }
};

export default class NumberCustom extends ReactComponent {

  static get builderInfo() {
    return {
      title: 'Number Custom',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: NumberCustom.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'numbercustom',
      label: 'Default Label',
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */


  attachReact(element) {
    const instance = ReactDOM.render(
      <Example
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
