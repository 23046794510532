import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const Card = props => {
  const { route } = props;

  return (
    <Fragment>
      <main >
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </Fragment>
  );
};

Card.propTypes = {
  route: PropTypes.object
};

export default Card;
