import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import settingsForm from './Recaptcha.settingsForm';
import ReCAPTCHA from 'react-google-recaptcha';

const TEST_SITE_KEY = '6LdG1dkaAAAAAPdOvrX96KRQ5baoNqwPZIAp8b1G';
//  const DELAY = 1500;

const Recaptcha = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      placeholder: props.component.placeholder,
      callback: 'not fired',
      load: false,
      expired: 'false',
      shouldCaptchaReset: 'true'
    };

    this.onChange = this.onChange.bind(this);
    this._reCaptchaRef = React.createRef();
  }

  handleChange = value => {
    this.setState({ value });
    if (value === null) this.setState({ expired: 'true' });
  };

  onChange(value) {
    this.setState({ value: value }, () =>
      this.props.onChange(this.state.value)
    );
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' });
  };

  grecaptchaObject = () => window.grecaptcha;

  render() {
    return (
      <div>
        <ReCAPTCHA
          theme="light"
          ref={this._reCaptchaRef}
          sitekey={TEST_SITE_KEY}
          value={this.state.value || ''}
          onChange={value => this.onChange(value)}
          asyncScriptOnLoad={value => this.asyncScriptOnLoad(value)}
          grecaptcha={this.grecaptchaObject}
          reset={this.state.shouldCaptchaReset}
        />
      </div>
    );
  }
};

export default class RecaptchaCustom extends ReactComponent {
  static get builderInfo() {
    return {
      title: 'RECAPTCHA Custom',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: RecaptchaCustom.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'reCaptchaCustom',
      label: 'Default Label'
    });
  }

  get emptyValue() {
    return '';
  }

  static editForm = settingsForm;

  attachReact(element) {
    const instance = ReactDOM.render(
      <Recaptcha
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.}
      />,
      element
    );

    return instance;
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
