import React, { Component } from 'react';
import styled from 'styled-components';

const RadioDiv = styled.div`
  input[type='radio'] {
    width: 20px !important;
    height: 20px !important;
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    margin-bottom:1rem;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 20px !important;
      height: 20px !important;
      background: ${props => props.color};
      margin-right: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      background: ${props =>
        `linear-gradient(135deg, ${props.color}, ${props.color},#f7f7f0) `};
      border-image-slice: 1;
      left: 0;
      top: 0;
      box-sizing: border-box;
      transition: all 300ms ease-in-out;
    }
    &:checked {
      background: white !important;
      border: ${props => `2px solid ${props.color} !important`};
      width: 20px !important;
      height: 20px !important;
      &::before {
        background: ${props =>
          `linear-gradient(135deg, ${props.color}, ${props.color},#f7f7f0) `};
        margin: 2px !important;
        width: 13px !important;
        height: 13px !important;
      }
    }
  }
`;

export default class CustomStyled extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { options, propertyValue, propertyLabel, onChange, value } = this.props;

    return (
      <>
        {options &&
          options.length > 0 &&
          options.map((item, index) => {
            return (
              <RadioDiv
                className="radio form-check-inline"
                color={item.code}
                key={index}>
                <label className="form-check-label label-position-top">
                  <input
                    type="radio"
                    id={item[propertyValue ?? 'value']}
                    name={item[propertyLabel ?? 'label']}
                    value={item[propertyValue ?? 'value']}
                    className="form-check-input"
                    onChange={onChange}
                    checked={value === item[propertyValue ?? 'value']}
                  />
                  <span for={item[propertyValue ?? 'value']}></span>
                </label>
              </RadioDiv>
            );
          })}
      </>
    );
  }
}
