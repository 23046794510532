import React from 'react';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { ScrollReset, GoogleAnalytics } from './components';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './context/AuthContext';
import { routWithFetchProvider as FetchProvider } from './context/FetchContext';

import theme from './theme';
import routes from './routes';

import configureStore from './store/store';
import {  Components} from '@formio/react';
import components from './components';
import CustomSnackBar from 'components/CustomSnackBar';

Components.setComponents(components);
const store = configureStore();

const App = () => {

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            content={(key, message) => (
              <CustomSnackBar id={key} message={message} />
            )}
            maxSnack={3}
            preventDuplicate>
            <AuthProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FetchProvider>
                  <ScrollReset />
                  <GoogleAnalytics />
                  {renderRoutes(routes)}
                </FetchProvider>
              </MuiPickersUtilsProvider>
            </AuthProvider>
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
