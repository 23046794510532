import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AUTH_TOKEN = 'token';
const DOCTOR_INFO = 'doctorInfo';

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
  const history = useHistory();

  const token = localStorage.getItem(AUTH_TOKEN);
  const doctorInfo = localStorage.getItem(DOCTOR_INFO);

  const [authState, setAuthState] = useState({
    token,
    doctorInfo: doctorInfo ? JSON.parse(doctorInfo) : {}
  });

  const setAuthInfo = ({ token, doctorInfo }) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(DOCTOR_INFO, JSON.stringify(doctorInfo));

    setAuthState({
      token,
      doctorInfo
    });
  };

  const logout = () => {
    history.push('/auth/login');
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(DOCTOR_INFO);
    setAuthState({});
  };

  const isAuthenticated = () => {
    if (!authState.token) {
      return false;
    }
    return true;
  };

  const isAdmin = () => {
    if (isAuthenticated && authState.doctorInfo.role === 'admin') {
      return true;
    }
    return false;
  };

  const isStaff = () => {
    if (isAuthenticated && authState.doctorInfo.role === 'staff') {
      return true;
    }
    return false;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
        isStaff
      }}>
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
